import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@/view/home/search'),
    meta: {
      title: '搜索',
    },
  },
  {
    name: 'Live',
    path: '/live',
    component: () => import('@/view/live/index'),
    meta: {
      title: '直播',
    },    
    children: [
      {
        path: '/live/:index',
        name: 'Live',
        component: () => import('@/view/live/index'),
        meta: { title: '直播'},
        children: [
          {
            path: '/live/:index/:time',
            name: 'Live',
            component: () => import('@/view/live/index'),
            meta: { title: '直播'}
          },
        ]
      },
    ]
  },
  {
    name: 'Address',
    path: '/address',
    redirect: '/address/list',
    meta: {
      title: '我的地址',
    },    
    children: [
      {
        path: 'list',
        name: 'List',
        component: () => import('@/view/address/index'),
        meta: { title: '我的地址'}
      },
      {
        path: 'add',
        name: 'Add',
        component: () => import('@/view/address/add'),
        meta: { title: '添加地址'},
        children: [
          {
            path: ':id',
            name: 'Add',
            component: () => import('@/view/address/add'),
            meta: { title: '编辑地址'},
          }
        ]
      },
    ]
  },
  {
    name: 'Paiwei',
    path: '/paiwei',
    redirect: '/paiwei/list',
    meta: {
      title: '我的地址',
    },    
    children: [
      {
        path: 'list',
        name: 'PaiweiList',
        component: () => import('@/view/paiwei/index'),
        meta: { title: '莲位列表'}
      },
      {
        path: 'add',
        name: 'PaiweiAdd',
        component: () => import('@/view/paiwei/add'),
        meta: { title: '添加莲位'},
        children: [
          {
            path: ':id',
            name: 'PaiweiAdd',
            component: () => import('@/view/paiwei/add'),
            meta: { title: '添加莲位'},
          }
        ]
      },
      {
        path: 'detail/:id',
        name: 'PaiweiDetail',
        component: () => import('@/view/paiwei/detail'),
        meta: { title: '莲位详情'}
      },
    ]
  },
  {
    name: 'Record',
    path: '/record',
    redirect: '/record/list',
    meta: {
      title: '功课纪录',
    },    
    children: [
      {
        path: 'list',
        name: 'RecordList',
        component: () => import('@/view/record/index'),
        meta: { title: '功課列表'}
      },
      {
        path: 'add',
        name: 'RecordAdd',
        component: () => import('@/view/record/add'),
        meta: { title: '增加记录'},
        children: [
          {
            path: ':id',
            name: 'RecordAdd',
            component: () => import('@/view/record/add'),
            meta: { title: '增加记录'},
          },
          {
            path: ':type',
            name: 'RecordAdd',
            component: () => import('@/view/record/add'),
            meta: { title: '增加记录'},
          }
        ]
      },
      {
        path: 'detail/:id',
        name: 'RecordDetail',
        component: () => import('@/view/record/detail'),
        meta: { title: '功课'}
      },
    ]
  },
  {
    name: 'Video',
    path: '/video',
    redirect: '/video/list',
    meta: {
      title: '影音',
    },
    children: [
      {
        path: 'list',
        name: 'VideoList',
        component: () => import('@/view/video/index'),
        meta: { title: '专辑列表'},
        children: [
          {
            path: ':time',
            name: 'VideoList',
            component: () => import('@/view/video/index'),
            meta: { title: '专辑列表'}
          },
        ]
      },
      {
        path: 'player/:aid',
        name: 'VideoPlayer',
        component: () => import('@/view/video/player'),
        meta: { title: '播放器'},
        children: [
        {
          path: ':id',
          name: 'VideoPlayer',
          component: () => import('@/view/video/player'),
          meta: { title: '播放器'},        
          children: [
            {
              path: ':time',
              name: 'VideoPlayer',
              component: () => import('@/view/video/player'),
              meta: { title: '播放器'},
            }
          ]
        },
      ],
      },
    ]
  },
  {
    name: 'Lecture',
    path: '/lecture',
    redirect: '/lecture/list',
    meta: {
      title: '讲演全集',
    },
    children: [
      {
        path: 'list',
        name: 'LectureList',
        component: () => import('@/view/lecture/index'),
        meta: { title: '专辑列表'},
        children: [
          {
            path: ':time',
            name: 'LectureList',
            component: () => import('@/view/lecture/index'),
            meta: { title: '专辑列表'}
          },
        ]
      },
      {
        path: 'search',
        name: 'SearchList',
        component: () => import('@/view/lecture/search'),
        meta: { title: '查询手册'}
      },
      {
        path: 'player/:num',
        name: 'LecturePlayer',
        component: () => import('@/view/lecture/player'),
        meta: { title: '播放器'},
        children: [
          {
            path: ':id',
            name: 'LecturePlayer',
            component: () => import('@/view/video/player'),
            meta: { title: '播放器'},
            children: [
              {
                path: ':time',
                name: 'LecturePlayer',
                component: () => import('@/view/video/player'),
                meta: { title: '播放器'},
              }
            ]
          }
        ],
      },
    ]
  },
  {
    name: 'Radio',
    path: '/radio',
    redirect: '/radio/list',
    meta: {
      title: '电台',
    },
    children: [
      {
        path: 'list',
        name: 'RadioList',
        component: () => import('@/view/radio/index'),
        meta: { title: '专辑列表'},
        children: [
          {
            path: ':time',
            name: 'RadioList',
            component: () => import('@/view/radio/index'),
            meta: { title: '专辑列表'}
          },
        ]
      },
      {
        path: 'player/:aid',
        name: 'RadioPlayer',
        component: () => import('@/view/radio/player'),
        meta: { title: '播放器'},
        children: [
          {
            path: ':id',
            name: 'RadioPlayer',
            component: () => import('@/view/radio/player'),
            meta: { title: '播放器'},
            children: [
              {
                path: ':time',
                name: 'RadioPlayer',
                component: () => import('@/view/radio/player'),
                meta: { title: '播放器'}
              },
            ]
          },
        ]
      },
    ]
  },
  {
    name: 'Essence',
    path: '/essence',
    redirect: '/essence/list/0',
    meta: {
      title: '图文精华',
    },
    children: [
      {
        path: 'list/:aid',
        name: 'EssenceList',
        component: () => import('@/view/essence/index'),
        meta: { title: '列表'}
      },
      {
        path: 'detail/:id',
        name: 'EssenceDetail',
        component: () => import('@/view/essence/detail'),
        meta: { title: '详情'},
        children: [
          {
            path: ':time',
            name: 'EssenceDetail',
            component: () => import('@/view/essence/detail'),
            meta: { title: '详情'},
          },
        ]
      },
      
    ]
  },
  {
    name: 'Infomation',
    path: '/info',
    redirect: '/info/list/0',
    meta: {
      title: '资讯',
    },
    children: [
      {
        path: 'list/:aid',
        name: 'InfoList',
        component: () => import('@/view/info/index'),
        meta: { title: '列表'}
      },
      {
        path: 'detail/:id',
        name: 'InfoDetail',
        component: () => import('@/view/info/detail'),
        meta: { title: '详情'},
        children: [
          {
            path: ':time',
            name: 'InfoDetail',
            component: () => import('@/view/info/detail'),
            meta: { title: '详情'},
          },
        ]
      },
    ]
  },
  {
    name: 'Picture',
    path: '/picture',
    redirect: '/picture/list',
    meta: {
      title: '图集',
    },
    children: [
      {
        path: 'list',
        name: 'PictureList',
        component: () => import('@/view/picture/index'),
        meta: { title: '列表'}
      },
      {
        path: 'detail/:id',
        name: 'PictureDetail',
        component: () => import('@/view/picture/detail'),
        meta: { title: '详情'}
      },
    ]
  },
  {
    name: 'Mine',
    path: '/mine',
    redirect: '/mine/index',
    meta: {
      title: '我的',
    },
    children: [
      {
        path: 'index',
        name: 'MineIndex',
        component: () => import('@/view/mine/index'),
        meta: { title: '我的'}
      },
    ]
  },
  {
    name: 'Special',
    path: '/sp',
    redirect: '/special/index',
    meta: {
      title: '專題',
    },
     children: [
      {
        path: 'live/:time',
        name: 'SpecialPlayer',
        component: () => import('@/view/special/index'),
        meta: { title: '專題播放器'},
      },  
      {
        path: 'cgapp/:id',
        name: 'CiguangApp',
        component: () => import('@/view/special/cgapp'),
        meta: { title: '慈光讲堂App'},
      },      
      {
        path: 'player',
        name: 'PlayerTest',
        component: () => import('@/view/special/player'),
        meta: { title: '点播测试'},
      }
    ]
  },
  {
    name: 'redirect',
    path: '/u/:id',
    component: () => import('@/view/redirect/index'),
    meta: {
      title: '跳转',
    },
  },  
  {
    name: 'app',
    path: '/app/:id',
    component: () => import('@/view/app/index'),
    meta: {
      title: 'App',
    },
  },
];

const router = createRouter({
  mode: "history",
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
