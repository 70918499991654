export default {
  name: 'Home',

  data() {
    return {
      isShow: true
    };
  },

  mounted() {
    this.$dialog.alert({
      allowHtml: true,
      messageAlign: "left",
      confirmButtonText: '关 闭',
      title: '温馨提示！',
      message: '为避免无法播放，请尽量不要分享至朋友圈，感恩，阿弥陀佛！'
    });
  },

  metaInfo: {
    title: '智者行一',
    meta: [{
      charset: 'utf-8'
    }, {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
    }]
  }
};